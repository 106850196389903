<template>
  <img
    class="border-solid border-black border-[1px] object-cover"
    :class="{
      'h-[200px] w-[200px] rounded-[100px]': size === 'lg',
      'h-[50px] w-[50px] rounded-[25px]':
        size === 'md',
      'h-[32px] w-[32px] rounded-[16px]': size === 'sm',
    }"
    v-if="(imageId == undefined || imageId === 0) && platform === 'bikeable'"
    src="~/assets/bikeable-profilbild.svg"
    :alt="$t('accessibility.profile_image')"
  />
  <img
      class="border-solid border-black border-[1px] object-cover"
      :class="{
      'h-[200px] w-[200px] rounded-[100px]': size === 'lg',
      'h-[50px] w-[50px] rounded-[25px]':
        size === 'md',
      'h-[32px] w-[32px] rounded-[16px]': size === 'sm',
    }"
      v-else-if="(imageId == undefined || imageId === 0) && platform === 'walkable'"
      src="~/assets/walkable-profilbild.svg"
      :alt="$t('accessibility.profile_image')"
  />
  <img
    v-else
    class="border-black border-solid border-[1px] object-cover"
    :class="{
      'h-[200px] w-[200px] rounded-[100px]': size === 'lg',
      'h-[32px] w-[32px] rounded-[16px] sm:h-[50px] sm:w-[50px] sm:rounded-[25px]':
        size === 'md',
      'h-[32px] w-[32px] rounded-[16px]': size === 'sm',
    }"
    :src="useImageUrl(imageId!, size === 'lg' ? 'medium' : 'small')"
    :alt="$t('accessibility.profile_image')"
  />
</template>
<script setup lang="ts">
defineProps({
  imageId: { type: Number, required: false, default: undefined },
  size: { type: String as () => "sm" | "md" | "lg", required: false, default: "sm" }
});

const platform = usePlatformSettings().platformName;
</script>
